@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.admin__faq {
    box-sizing: border-box;
    position: fixed;
    top: 50%;
    left: 70%;
    transform: translate(-71%, -50%);
    background: #ffffff;
    border: 1px solid #446647;
    justify-content: center;
    gap: 65px;
    padding: 20px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-admin-faq-container {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.admin-modal-faq {
    box-sizing: border-box;
    width: 550px;
    position: fixed;
    top: 50%;
    left: 70%;
    transform: translate(-72%, -50%);
    background: #ffffff;
    border: 1px solid #446647;
    justify-content: center;
    gap: 65px;
    padding: 40px 20px 20px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.modal-admin-faq-title-container {
    padding: 0;
    margin-bottom: 1rem;
    text-align: center;
}

.modal-admin-faq-title {
    font-size: 36px;
    font-weight: 700;
    text-align: center;
}

.faq__form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.modal-admin-faq-input-container {
    width: 550px;
    height: 400px;
    max-width: 550px;
}

.modal-admin-faq-input-field {
    box-sizing: border-box;
    width: 550px;
    height: 150px;   
    align-content: left;
    font-size: 16px;
    font-weight: 700;
    font-family: "inter";
    color: #676767;
    background: #F1F1F1;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #446647;

    margin-bottom: 10px;
    text-align: left;
}

.modal-admin-faq-input-field::placeholder {
    font-size: 16px;
}

.modal-admin-faq-buttons-container {
    display: flex;
    justify-content: center;
}

.modal-admin-faq-button {
    align-self: flex-end;
    background: linear-gradient(180deg, #82ac5c 0%, #78a67a 100%);
    border-radius: 5px;
    color: white;
    border: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    padding: 6px 24px;
    box-shadow: 0px 5px 5px lightgray;
    margin-right: 10px;
}

.modal-admin-faq-button:hover {
    box-shadow: 0px 5px 5px gray;
}

.modal-admin-faq-button:active {
    transform: scale(0.98);
}

.modal-admin-faq-close-form {
    box-sizing: border-box;
    width: 35px;
    height: 35px;
    position: absolute;
    right: 1.25%;
    top: 1.3%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    text-align: center;
    margin: 0 auto;
    font-weight: 600;
    color: #446647;
    font-family: "Arial", Courier, monospace;
    border: solid 3px #446647;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2 ease-in;
    padding-left: 1px;
}