@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.service-card-container {
  box-sizing: border-box;
  border: 1px solid #000000;
  width: 100%;
  height: inherit;
  background-color: #cccccc;
  position: relative;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;
}

#service-title-container {
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.4);
  color: #fafffd;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: ease-out 0.4s;

  padding: 0 24px 0;
}

#service-desc-container {
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: inherit;
  margin: 0px;
  color: #fafffd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 30px 24px 0;
  opacity: 0;
  transition: ease-in 0.4s;
  overflow:auto;
}

.service-card-container:hover #service-desc-container {
  opacity: 1;
}
.service-card-container:hover #service-title-container {
  opacity: 0;
}

#service-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
}

#service-learn-more {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;

  display:none;
}

#service-desc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  margin: 20px 0 24px;
  text-align: center;
}

#service-desc__left {
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  display: flex;
  margin: 32px 0 30px;
  white-space: pre-line;
}

.service__btn {
  margin-top: 20px;
  padding-left: 52%;
}

@media only screen and (max-width: 800px) {
  #service-desc-container {
    padding: 20px 15px 20px;
  }
  #service-desc {
    margin: 17px 0 0;
  }
  #service-desc__left {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin: 17px 0 0;
  }
  #service-title-container {
    height: 80px;
    justify-content: space-between;
    padding: 16px 0;
  }
  #service-title {
    font-size: 20px;
    line-height: 24px;
  }
  #service-learn-more {
    font-size: 20px;
    line-height: 24px;
    text-decoration: underline;

    display: block;
  }

}
