/* CSS is subject to change later */

.product__page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product__img {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
}
.hero__img {
  min-width: 100%;
  height: auto;
  margin-bottom: 120px;
}

.product-page-content-container {
  width: 72%;
  max-width: 1024px;
}

.product__title {
  height: 64px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  color: #000000;

  margin-bottom: 9px;
}

.product-subtitle-container {
  display: flex;
  justify-content: center;
}

.product__subtitle {
  margin-bottom: 0 auto 60px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: #000000;
}

.product__cardGroup {
  width: 100%;
  margin: 80px 0 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8rem 2rem;
}

.product__card {
  justify-self: center;
  width: auto;
  min-width: 415px;
  max-width: 415px;
  height: 498px;
  text-align: center;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1200px) {
  .product__cardGroup {
    grid-template-columns: 1fr;
    grid-gap: 6rem 2rem;
  }

}

@media only screen and (max-width: 800px) {
  .hero__img {
    margin-bottom: 58px;
  }

  .product-page-content-container {
    width: 90%;
  }

  .product__title {
    height: 24px;

    font-size: 18px;
    line-height: 22px;
  }

  .product__subtitle {
    font-size: 14px;
    line-height: 17px;
  }
}

@media only screen and (max-width: 640px) {
  .product__card {
    width: 315px;
    min-width: 315px;
    max-width: 315px;
    height: 378px;
  }

}