@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

:root {
    --title-max-font-size: 36px;
    --desc-max-font-size: 24px
}

.team-cardContainer {
    box-sizing: border-box;
    border: 1px solid #000000;
    width: inherit;
    height: inherit;
    background-color: #cccccc;
    position: relative;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
}

#team-title-container {
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.5);
    color: #fafffd;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: ease-out 0.4s;
}

#team-desc-container {
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    margin: 0px;
    color: #fafffd;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: ease-in 0.4s;
}

.team-cardContainer:hover #team-desc-container {
    opacity: 1;
}
.team-cardContainer:hover #team-title-container {
    opacity: 0;
}

#team-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: var(--title-max-font-size);
    line-height: 44px;
    margin-top: 16px 0 7px;
}

#team-desc {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: var(--desc-max-font-size);
    line-height: 29px;
    display: flex;
    margin: 0px;
    text-align: center;
}

#team-desc__left {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    display: flex;
    margin: 0 30px;
}

.team-booking-btn-container {
    box-sizing: border-box;
    justify-self: flex-end;
    margin-top: 15px;
    display: flex;
    justify-content: right;
    position: relative;
    display: none;
}

.service__btn {
    margin-top: 20px;
    padding-left: 52%;
}

@media only screen and (max-width: 1200px) {
    #team-title {
        font-size: calc(var(--title-max-font-size)*.8);
    }
    #team-desc {
        font-size: calc(var(--desc-max-font-size)*.8);
    }
    
}


@media only screen and (max-width: 1024px) {
    #team-desc {
        margin: 8px 0 12px;
    }
}


@media only screen and (max-width: 800px) {
    #team-desc-container, 
    .team-cardContainer:hover #team-desc-container {
        visibility: hidden;
    }
    #team-title-container {
        height: 60px;
    }
    .team-cardContainer:hover #team-title-container {
        opacity: 1;
    }
    #team-title {
        font-size: 14px;
        line-height: 17px;
    }
    #team-desc {
        font-size: 12px;
        line-height: 15px;
        margin: 5px 0 0 0;
    }
    .team-booking-btn-container {
        display: flex;
    }
}
