.footer-container {
  background-color: #456647;
  color: #f5f5f5;
  padding: 40px 20px 0px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
}

.contact-info,
.hours-info,
.site-nav,
.socials-logo {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1024px) {

  .footer-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

}

@media only screen and (max-width: 640px) {
  
  .footer-container {
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 40px 40px;  
  }

}
