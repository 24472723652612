#admin-user-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-wrapper{
  width: 80%;
  height: 100%;
  padding-top:44px;
}
.admin-comp-container {
  display: flex;
  margin-top:10px;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;

}

#title-wrapper {
  width: 100%;
  font-size: 40px;
  margin-bottom: 50px;
}

#user-list-title {
  text-align: left;
  font-size: 40px;
  font-weight: 700;
}
.button-wrapper {
  width: 100%;
  margin-top:50px;
  text-align: center;
}

.button-admin {
  width: 250px;
  height: 50px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: linear-gradient(180deg, #82ac5c 0%, #78a67a 100%);
  border: none;
  box-shadow: 0px 5px 5px lightgray;
  color: #ffffff;
}

.button-admin:hover {
  transform: scale(1.02);
}

.button-admin:active {
  transform: scale(1);
}

.users__layout { 
  width: 100%;
  margin-top: 68px;
  display: flex;
  flex-direction: row;
}

.users-grid-container {
  width: 100%;
  display: grid;
  grid-template-columns: auto;
}