.conditions_page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contitions-content-container {
  box-sizing: border-box;
  width: 72%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.condition__title {
  height: 58px;
  margin-top: 120px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 58px;
  text-align: center;

  color: #000000;
}

.condition__search {
  width: 83%;
  max-width: 846px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
}

.search__input {
  box-sizing: border-box;
  padding: 10px 20px;
  gap: 10px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #5a736d;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  /* or 167% */

  display: flex;
  align-items: center;
  letter-spacing: 0.03em;

  color: rgba(90, 115, 109);

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.condition__cardGroup {
  box-sizing: border-box;
  width: 100%;
  max-width: 1024px;
  margin-top: 80px;
  justify-content: space-around;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12rem 6rem;
}

.condition-card-container {
  justify-self: center;
  box-sizing: border-box;
  width: 415px;
  height: 500px;

  border: 0 solid black;
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 100px 0 140px;
}

.pagination button {
  background: none;
  border: none;
  cursor: pointer;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 16px;
  color: #5a736d;
  margin: 0 20px;
}

@media only screen and (max-width: 1024px) {
  .contitions-content-container {
    width: 90%;
  }
  .condition__cardGroup {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .condition__title {
    font-weight: 500;
    font-size: 18px;
    height: 24px;
    line-height: 22px;
    margin: 60px 0px 0px 0px;
  }
  .condition__search {
    margin-top: 30px;
  }
  .search__input {
    padding: 6px 23px;
  }
  .condition__cardGroup {
    margin-top: 40px;
    grid-gap: 2.5rem 2.5rem;
  }
  .condition-card-container {
    width: 311px;
    height: 375px;
  }
  .pagination {
    margin: 49px 0 100px;
  }
  .pagination button {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.01em;
  }
}
