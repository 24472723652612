.faq-container {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#faq-main-image {
  width: 100%;
  height: 100%;
}

#faq-info-container {
  width: 80%;
  max-width: 1024px;
  margin-bottom: 20px;
}

#faq-title {
  margin: 120px 0 80px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  color: #000000;
}

.faq-quest-comp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.5rem;
}

#faq-quest-title {
  font-family: "Inter";
  margin-left: 2vw;
  font-style: normal;
  font-weight: 700;
  width: 100%;
  font-size: 24px;
  line-height: 29px;
  white-space: pre-line;
  /* identical to box height */

  color: #000000;

  /* Inside auto layout */

  /* flex: none;
  order: 1;
  flex-grow: 0; */
}

#hard-line {
  margin-top: 1vh;
  width: 100%;
  height: 0px;
  border: 1px solid rgba(0, 0, 0, 0.45);
  /* Inside auto layout */
  flex: none;
  order: 2;
  flex-grow: 0;
}

#greenbox__front {
  position: relative;
  width: 67px;
  height: 64px;
  min-width: 20px;
  max-width: 64px;
  min-height: 20px;
  max-height: 64px;
  background: #446647;
  
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

#greenbox:hover {
  transform: scale(1.1);
}

#greenbox:active {
  transform: scale(1);
}

#plus-sign-hort {
  position: absolute;
  width: 60%;
  height: 0px;
  left: 0px;
  top: 0px;
  border: 2px solid #ffffff;
  transform: rotate(-90deg);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#plus-sign-vert {
  position: absolute;
  width: 0%;
  height: 60%;
  left: 0px;
  top: 0;
  border: 2px solid #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.1s ease-out;
  opacity: 1;
}

#plus-sign-vert-open {
  opacity: 0;
}

#ans {
  padding-top: 10px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  white-space: pre-line;
}

@media only screen and (max-width: 800px) {
  #faq-info-container {
    width: 90%;
  }
  #greenbox__front {
    width: 55px;
    height: 55px;
  }
  #faq-quest-title {
    font-size: 20px;
    line-height: 24px;
  }
  #ans {
    font-size: 16px;
    line-height: 20px;
  }
}

@media only screen and (max-width: 640px) {
  #faq-title {
    margin: 59px 0 31px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }
  #greenbox__front {
    width: 50px;
    height: 50px;
  }
  #faq-quest-title {
    font-size: 16px;
    line-height: 19px;
  }
  #ans {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }

}
