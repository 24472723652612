.modal-admin-rates-container {
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 70%;
  transform: translate(-70%, -50%);
  width: 400px;
  background: #ffffff;
  border: 1px solid #446647;
  justify-content: center;
  gap: 65px;
  padding: 15px 0px 30px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  display: flex;
  flex-direction: column;
  gap: 25px;
}

.modal-admin-rates-content-container {
  width: 90%;
}

.admin-ratesHeader {
    padding: 0;
    margin-bottom: 1rem;
    text-align: center;
}

.condition__form {
  display: flex;
  flex-direction: column;
}

.condition__form div {
  margin-bottom: 5px;
}

.modal-admin-rates-close-form {
  box-sizing: border-box;
  width: 35px;
  height: 35px;
  position: absolute;
  right: 1.25%;
  top: 1.3%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  text-align: center;
  margin: 0 auto;
  font-weight: 600;
  color: #446647;
  font-family: "Arial", Courier, monospace;
  border: solid 3px #446647;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2 ease-in;
  padding-left: 1px;
}

.modal-admin-rates-title {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
}

.modal-admin-rates-input-container {
  width: 100%;
}

.modal-admin-rates-input-field {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 700;
  font-family: "inter";
  color: #676767;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.modal-admin-rates-label {
  font-size: 16px;
}

.modal-admin-rates-checkbox-field {
  box-sizing: border-box;
  width: 7%;
  font-size: 16px;
  height: 16px;
  margin: 10px 0;
}

.modal-admin-rates-button {
  align-self: flex-end;
  background: linear-gradient(180deg, #82ac5c 0%, #78a67a 100%);
  border-radius: 5px;
  color: white;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  padding: 6px 24px;
  box-shadow: 0px 5px 5px lightgray;
  margin-right: 10px;
}

.modal-admin-rates-button:hover {
  box-shadow: 0px 5px 5px gray;
}

.modal-admin-rates-button:active {
  transform: scale(0.98);
}

