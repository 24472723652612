@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.admin__delete {
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 70%;
  transform: translate(-71%, -50%);
  background: #ffffff;
  border: 1px solid #446647;
  justify-content: center;
  gap: 65px;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-admin-delete-container {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.admin-modal-faq {
  box-sizing: border-box;
  width: 550px;
  position: fixed;
  top: 50%;
  left: 70%;
  transform: translate(-72%, -50%);
  background: #ffffff;
  border: 1px solid #446647;
  justify-content: center;
  gap: 65px;
  padding: 40px 20px 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-admin-delete-title-container {
  padding: 0;
  margin-bottom: 1rem;
  text-align: center;
}

.modal-admin-faq-title {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
}

.delete__form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.delete__modal__container {
  width: 360px;
  max-width: 550px;
  margin: 0 0 30px 0;
}

.modal-admin-delete-buttons-container {
  display: flex;
  justify-content: center;
}

.modal-admin-delete-close-form {
  box-sizing: border-box;
  width: 35px;
  height: 35px;
  position: absolute;
  right: 1.25%;
  top: 1.3%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  text-align: center;
  margin: 0 auto;
  font-weight: 600;
  color: #446647;
  font-family: "Arial", Courier, monospace;
  border: solid 3px #446647;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2 ease-in;
  padding-left: 1px;
}

.modal-admin-delete-button {
  align-self: flex-end;
  background: linear-gradient(180deg, #82ac5c 0%, #78a67a 100%);
  border-radius: 5px;
  color: white;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  padding: 6px 24px;
  box-shadow: 0px 5px 5px lightgray;
  margin-right: 10px;
}

.modal-admin-delete-button:hover {
  box-shadow: 0px 5px 5px gray;
}

.modal-admin-delete-button:active {
  transform: scale(0.98);
}
