.admin__product {
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 82%;
  transform: translate(-84%, -50%);
  background: #ffffff;
  border: 1px solid #446647;
  justify-content: center;
  padding: 20px 10px;
  width: 600px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-admin-product-title-container {
  margin-bottom: 10px;
}

.modal-admin-product-title {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
}

.product__section {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.img__section {
  box-sizing: border-box;
  height: 217px;
  width: 223px;
  border: solid 0.5px black;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
}

.product__modal__img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.product__name__input {
  border: 0;
  outline: 0;
  background: #f1f1f1;
  border-bottom: 1px solid #446647;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  padding: 10px 0;
  width: 280px;
}

.product__textarea__input {
  border: 0;
  outline: 0;
  background: #f1f1f1;
  border-bottom: 1px solid #446647;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  padding: 10px 0;
  width: 100%;
  text-align: left;
}

textarea::placeholder {
  text-align: left;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
}

.product__modal__btn {
  align-self: flex-end;
  background: linear-gradient(180deg, #82ac5c 0%, #78a67a 100%);
  border-radius: 5px;
  color: white;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  padding: 6px 24px;
  box-shadow: 0px 5px 5px lightgray;
  margin-right: 10px;
}

.product__modal__btn:hover {
  box-shadow: 0px 5px 5px gray;
}

.product__modal__btn:active {
  transform: scale(0.98);
}