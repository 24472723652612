.admin__product__page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-grid-container {
  width: 100%;
  display: grid;
  grid-template-columns: auto;
}

.admin-product-container {
  width: 80%;
}

.admin-products-title-add-container {
  display: flex;
  flex-direction: row;
  margin-top: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-products-title-wrapper {
  display: flex;
}

#admin-products-title {
  text-align: center;
  font-size: 40px;
  font-weight: 700;
}

.product__list {
  width: 100%;
  display: flex;
  margin-top: 10px;
}

.product-picture-container {
  box-sizing: border-box;
  width: 227px;
  min-width: 227px;
  max-width: 227px;
  max-height: 175px;
  margin: 0 10px 0 0;
  display: flex;
  justify-content: center;
}

.product__img {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.product-no-picture {
  box-sizing: border-box;
  width: 227px;
  height: 175px;
  max-width: 227px;
  max-height: 175px;  
  border: solid 0.5px black;
  text-align: center;
  display:flex;
  justify-content: center;
  align-items: center;  
}

.product__layout {
  width: 100%;
  margin-top: 68px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
}

.product__nameCard {
  box-sizing: border-box;
  width: 100%;
  min-height: 35px;
  padding: 5px 4px 3px;
  background: #f1f1f1;
  border-bottom: 1px solid #446647;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  white-space: pre-line;
  /* identical to box height */

  display: flex;
  flex-direction: column;

  color: #676767;
}

.product__group {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.product__btn {
  align-self: flex-end;
  padding: 0 10px;
  width: 250px;
  height: 35px;

  background: linear-gradient(180deg, #82ac5c 0%, #78a67a 100%);
  border-radius: 10px;
  color: white;
  border: none;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;

  box-shadow: 0px 5px 5px lightgray;
}

.product__btn:hover {
  transform: scale(1.02);
}

.product__btn:active {
  transform: scale(1);
}

@media only screen and (max-width: 1024px) {
  
  .admin-products-title-add-container {
    flex-direction: column;
  }  

  .product__list {
    padding: 10px 0px 10px 0;
    flex-direction: column;
    align-items: center;
  }  

  .product-picture-container {
    width: 100%;
  }

  .product-no-picture {
    width: 100%;
  }
  
}