.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 600px;
  height: 50%;
  background: #ffffff;
  border: 1px solid #446647;
  justify-content: center;

  padding: 20px 10px;
  border-radius: 20px;
}

#form-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5vh;
  align-items: center;
}


.close-form {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 1.25%;
  top: 1.3%;
  bottom: 92.18%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: 700;
  color: #446647;
  font-family: "Courier New", Courier, monospace;
  border: solid 5px #446647;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2 ease-in;

}

.auth-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:5vh;
}

#login-title{
  font-size: 48px;
  font-weight: bold;
  color:black;
}

.auth-profile-pic {
  height: 20vh;
  min-height: 100px;
  max-height: 150px;
}

.close-form:hover {
  transform: scale(1.1);
}

.close-form:active {
  transform: scale(1);
}

.input-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3vh;
  width: inherit;
}
.auth-input {
  box-sizing: border-box;
  width: 100%;
  max-width: 625px;
  height: 50px;
  left: 88px;
  top: 338px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  background: #f1f1f1;
  border-bottom: 1px solid #446647;
}

.button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.auth-button {
  background: linear-gradient(180deg, #82ac5c 0%, #78a67a 100%);
  border:none;
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  width: 200px;
  height: 50px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.auth-button:hover {
  transform: scale(1.1);
}

.auth-button:active {
  transform: scale(1);
}



@media (max-width: 600px){
  .close-form {
    width: 30px;
    height: 30px;
    font-size: 25px;
    font-weight: 700;
    border: solid 3.9px #446647;
  }
  .auth-input {
    font-size: 18px;
  }
}

@media (max-height: 1024px){
  .auth-container {
    height: 60%;
  }
}

@media (max-height: 920px){
  .auth-container {
    height: 65%;
  }
}

@media (max-height: 900px){
  .auth-container {
    height: 68%;
  }
}

@media (max-height: 750px){
  .auth-container {
    margin-top: 0px;
    height: 72%;
  }
}

@media (max-height: 650px){
  #login-title{
    font-size: 24px;
  }
}

@media (max-height: 600px){
  .auth-container {
    height: 70%;
  }
  .auth-content-container {
    gap: 20px;
  }
  #form-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
}

