/* ---- Business Information CSS below ---- */

.contact-info {
  margin: 0px 0px 24px 12px;  
}

.contact-items {
  width: 200px;
  margin: 0px 0px 48px 12px;
  display: flex;
  flex-direction: row;
}

.footer-icons {
  margin-right: 15px;
}

a {
  text-decoration: none;
  color: #f5f5f5;
}

a:hover {
  text-decoration: underline;
}

#admin-login{
  cursor: pointer;
}

/* ---- Hours Info CSS below ---- */

.hours-info {
  margin: 0px 0px 24px 12px;
}

.hours-items {
  width: 200px;
  font-size: 16px;
}

.hours-header {
  text-decoration: underline;
  margin-top: 0px;
}

.hours {
  display: flex;
  align-items: center;
  margin: 0px 0px 20px 0px;
}

.hours-date {
  font-weight: 700;
  margin: 3px;
}
.hours-time {
  font-size: 16px;
  margin-left: 10px;
}

/* ---- Site Navingation CSS below ---- */

.site-nav {
  margin: 0px 0px 24px 12px;
}

.site-nav-header {
  text-decoration: underline;
  margin-top: 0px;
}

.site-nav-links {
  display: flex;
  flex-direction: column;
}

.footer-site-navlink {
  margin-bottom: 5px;
  font-size: 16px;
  margin: 0px 0px 16px 0px;
}
.footer-site-navlink:hover {
  text-decoration: underline;
}

/* ---- Socials CSS below ---- */

.socials-container {
  display: flex;
  flex-direction: column;
  align-items: space-evenly;
  justify-content: space-evenly;
  margin: 0px 0px 24px -4px;
}

.socials-logo-container {
  display: flex;
  justify-content: center;
}

.socials-logo {
  object-fit: contain;
}

.socials-header {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  text-decoration-line: underline;

  color: #FFFFFF;

  margin: 15px 0 20px 0;
}

.socials-header-icons-container {
  display: flex;
  flex-direction: column;
}

.socials-icons-block {
  display: flex;
  justify-content: space-evenly;
}

.socials-icons {
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
}

@media only screen and (max-width: 1024px) {

  .contact-items {
    margin: 0px 0px 16px 0px;
  }

  .hours {
    margin: 0px 0px 16px 0px;
  }

  .socials-container {   
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .socials-logo-container {
    justify-content: flex-start;
    padding: 0;
  }

  .socials-icons-block {
    display: flex;
    justify-content: flex-start;
  }

  .socials-icons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .socials-header {
    margin: 0px 0 15px 0;
    text-align: left;
  }

}

@media only screen and (max-width: 414px) {

  .footer-container {
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 20px 40px;
    
  }
}

