.nav-link {
    box-sizing: border-box;
    width: inherit;
    height: inherit;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.nav-link:hover {
    text-decoration: none;
}

@media only screen and (max-width: 800px) {
    .nav-link {
        justify-content: flex-start;
    }
}