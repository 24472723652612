#topbaradmin {
  width: 100%;
  height: 69px;
  box-sizing: border-box;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 4.59%;
  background: #ffffff;
  border-bottom: 1px solid #000000;
  display: flex;
  justify-content:space-between;
  align-items: center;
}

#left-topbar{
    display: flex;
}

#right-topbar{
    display: flex;
    flex-direction: row;
    margin-right: 30px;
    align-items: center;
}

.topbar-logo {
  height: 75px;
}

.topbar-user-image {
  width: 40px;
  height: 40px;
  margin: 0 14px;
  border-radius: 50%;
  object-fit: cover;
}

.topbar-username {
  font-size: 19px;
  font-weight: 700;
  margin-right: 3px;
}

.topbar-logout-container {
  background-color: #82AC5C;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  height: 20px;
  text-align: center;
  padding: 1px 5px;
  border-radius: 5px;
}

.topbar-no-avatar {
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border: solid 0.5px black;
  border-radius: 50%;
  text-align: center;
  display:flex;
  justify-content: center;
  align-items: center;

  margin: 0 14px;
  font-size: 10px;
}