@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.ourProcess-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ourProcess-content-container {
  width: 80%;
  max-width: 1034px;
}

.ourProcess-banner img {
  width: 100%;
}

.aboutUs-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 120px 0px;
}

.card-body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
}

.cesar-profile {
  width: 368px;
  height: 448px;
  margin-right: 48px;
}

.cesar-profile > img {
  object-fit: contain;
  width: inherit;
  height: inherit;
}

.aboutUs-text-lg-screen {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.aboutUs-text-sm-screen {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: none
}

.card-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 58px;
  text-align: center;

  color: #000000;
}

.quadProcess {
  justify-content: space-around;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 1023px;
}

.quad-card {
  justify-self: center;
  width: 98%;
  min-width: 248px;
  max-width: 502px;
  height: 392px;
  max-height: 392px;
  min-height: 195px;
  margin-bottom: 100px;
  background: linear-gradient(
    180deg,
    rgba(114, 158, 116, 0.7) 0%,
    #528157 100%
  );
  border-radius: 10px;
}

.quad-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 38px;
  color: #ffffff;
  font-family: "Inter";
}

.quad-img {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}
.quad-img > img {
  width: inherit;
  height: inherit;
}

.quad-title {
  margin-bottom: 7px;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
}

.quad-desc {
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

@media only screen and (max-width: 1200px) {
  .quadProcess {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 1024px) {
  .ourProcess-content-container {
    width: 90%;
  }
  .cesar-profile {
    width: calc(368px*.8);
    height: calc(448px*0.8);
    margin-right: 24px;
  }
  .aboutUs-text-lg-screen {
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
  }

}

@media only screen and (max-width: 800px) {
  .ourProcess-content-container {
    width: 75%;
  }
  .aboutUs-card {
    margin: 60px 0px;
  }
  .card-title {
    font-size: 18px;
    line-height: 22px;
  }
  .card-body {
    margin-top: 24px;
  }
  .cesar-profile {
    display: none;
  }
  .aboutUs-text-lg-screen {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: none;
  }
  .aboutUs-text-sm-screen {
    font-size: 14px;
    line-height: 17px;
    display: block;
  }
  #more {
    display: none;
  }
  .read-btn-container {
    display: flex;
    justify-content: flex-end;
  }
  .readBtn {
    border: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    justify-self: right;
  }

  .quad-card {
    width: 248px;
    height: 195px;
  }
  .quad-card-content {
    padding: 16px;
  }
  .quad-card {
    margin-bottom: 12px;
  }
  .quad-img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  .quad-title {
    margin-bottom: 3px;
    font-size: 14px;
    line-height: 17px;
  }
  .quad-desc {
    font-size: 10px;
    line-height: 12px;
  }
  .quadProcess {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 80px;
  }
  
}

@media only screen and (max-width: 700px) {
  .quadProcess {
    grid-template-columns: 1fr;
  }
}
