.admin-rate-component-info {
  display: flex;
  flex-direction: column;
}

.admin-rate-component-field-container {
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  padding: 1px 3px 2px 3px;

  background: #F1F1F1;
  border-bottom: 1px solid #446647;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  flex-direction: column;

  color: #676767;
}

.admin-rate-field-title {
  color: black;
  font-size: 12px;
}