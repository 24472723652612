.contact__inputBox {
  font-size: 24px;
  height: 30px;
  margin-top: 10px;
  padding: 10px 0;
}

.checkbox__group {
  margin-top: 10px;
}

.contact-button-container {
  width: auto;
  max-width: 850px;
  display: flex;
  justify-content: flex-end;
}

#email__sent{
  position: fixed;
  background:white;
  border-radius:20px;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    width: 200px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}


#sent__logo{
  width:100px;
  animation-name: bounce-2;
  animation-timing-function: ease;
}

@keyframes bounce-2 {
  0%   { transform: translateY(0); }
  50%  { transform: translateY(-100px); }
  100% { transform: translateY(0); }
}

#email__text{
  color:darkgreen;
  padding:10px;
  font-size:30px;
}

@media only screen and (max-width: 800px) {
  .contact__inputBox {
    font-size: 14px;
    line-height: 17px;
    height: 18px;
  }
}