@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.product-card-container {
  box-sizing: border-box;
  border: 1px solid #000000;
  width: 100%;
  resize: horizontal;
  background-color: #cccccc;
  position: relative;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;
  background-size: contain;
}

#product-title-container {
  background: rgba(0, 0, 0, 0.4);
  color: #fafffd;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: ease-out 0.4s;
}

#product-learn-more-container {
    background: rgba(0, 0, 0, 0.4);
    color: #fafffd;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: ease-out 0.4s;

    display: none;
  }

#product-desc-container {
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    margin: 0px;
    color: #fafffd;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: ease-in 0.4s;

    padding: 20px 12px;
    white-space: pre-line;
}

.product-card-container:hover #product-desc-container {
  opacity: 1;
}
.product-card-container:hover #product-title-container {
  opacity: 0;
}
.product-card-container:hover #product-learn-more-container {
    opacity: 0;
}
  
#product-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
}

#product-desc {
    width: 100%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    margin: 0px;
    text-align: center;
    margin-bottom: 24px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

#product-learn-more {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;

    text-decoration: underline;
}

#product-desc__left {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  display: flex;
  margin: 0 30px;
}

.service__btn {
  margin-top: 20px;
  padding-left: 52%;
}

@media only screen and (max-width: 800px) {
    
    #product-title-container {
        height: 71px;
        top: 0;
    }

    #product-title {
        font-size: 20px;
        line-height: 24px;
    } 

    #product-learn-more-container {
        height: 71px;
        display: flex;
    }
    
    #product-learn-more {
        font-size: 20px;
        line-height: 24px;
    }
    
    #product-desc {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
    }

}
