.homePage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homePage-banner {
  width: 100%;
  height: auto;
  background-size: cover;
  background-position: center;
}

.banner-img {
  width: 100%;
}

.banner-mva-button-container {
  position: relative;
  bottom: 75px;
  right: 36px;
  display: flex;
  justify-content: flex-end;
  margin: 0 0 -110px 0;
  display: none;
}

/* ----- STATEMENT CSS STYLING ----- */

.homePage-statement {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  font-size: 48px;
  margin-bottom: 35px;
  margin: 0px 0px;
  text-align: center;
}

.statement-bubble {
  width: 84%;
  max-width: 1023px;
  background: linear-gradient(180deg, #78a67a 0%, #446647 100%);
  border-radius: 20px;
  height: 100%;
  min-height: 183px;
  padding: 8px;
  margin-top: 10px;
  color: #f5f5f5;
  font-size: 20px;
  font-weight: 500;
  display: flex;
}

.quoteLeft-container {
  align-self: flex-start;
  height: 100%;
  padding: 10px;
  display: flex;
}


.quoteRight-container {
  align-self: flex-end;
  height: 100%;
  padding: 10px;
  display: flex;
}

.quoteLeft-container {
  align-items: flex-start;
}
.quoteRight-container {
  align-items: flex-end;
}

.statement {
  align-self: center;
  font-style: italic;
  line-height: 28px;
  letter-spacing: 0.01em;
}

/* ----- SERVICES CSS STYLING ----- */

.home-services-container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 0;

  margin-top: 75px;
}

.images-container {
  margin-top: 35px;
  width: 84%;
  min-width: 315px;
  max-width: 1023px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.image-card {
  width: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  font-size: 22px;
  font-weight: 400;
}

.image-card-img {
  width: 100%;
  object-fit: contain;
  border-radius: 20px;
}

.img-title {
  margin-top: 15px;
}

/* ---- mva services section - home page ----- */

.mva-container {
  width: 84%;
  max-width: 1023px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mva-title {
  display: flex;
  justify-content: center;
  margin-bottom: 35px;
}

.mva-subText {
  margin-top: 20px;
  width: 100%;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
}

.mva-button-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* ---- our facilities section - home page ---- */

.facilities-container {
  width: 84%;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.carousel-container {
  margin-top: 35px;
  display: flex;
}

.facilities-carousel {
  box-sizing: border-box;
  width: 100%;
  min-width: 363px;
  max-width: 1100px;
}

.carousel .slide:hover {
  background-color: transparent;
}

.carousel-arrow-prev-container, .carousel-arrow-next-container {
  position: absolute;
  top: 50%;
  bottom: 50%;
  z-index: 50;
  font-size: 40px;
  opacity: 50%;
  display: flex;
  align-items: center;
}

.carousel-arrow-prev-container {
  left: 0%;
}

.carousel-arrow-next-container {
  right: 0%;
}

.carousel-arrow-prev-container:hover, .carousel-arrow-next-container:hover {
  opacity: 100%;
}

.carousel-arrow-prev, .carousel-arrow-next {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  color: black;
  font-size: 20px;
  border-radius: 50%;
  padding: 5px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.carousel-slide-container {
  width: 91%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
  gap: 20px;
}

.carousel-img-container {
  width: 100%; 
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-img {
  width: auto;
  height: inherit;
  margin: 0px;
  object-fit: contain;
}

/* ---- our location section - home page ---- */
.locations-container {
  margin-top: 118px;
  width: 84%;
  max-width: 1023px;
}

.locations-title {
  display: flex;
  justify-content: center;
  margin-bottom: 35px;
}

iframe {
  width: 100%;
  height: 450px;
  min-height: 278px;
  border: 1px solid #476a4a;
  border-radius: 10px;
}

/* ---- testimonial section - home page ----- */
.testimonial-container {
  width: 84%;
  max-width: 1023px;
  margin-top: 75px;
  margin-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial-carousel {
  width: 100%;
}

@media only screen and (max-width: 1024px) {

  h1 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 800px) {

  h1 {
    font-size: 18px;
  }

  .statement-bubble {
    box-sizing: border-box;
    font-size: 14px;
    flex-direction: column;
    padding: 0px 15px;
  }

  .image-card {
    width: 153px;
  }

  .image-card-img {
    width: 100%;
  }

  .img-title {
    margin-top: 5px;
    font-size: 14px;
  }

  .mva-subText {
    margin-top: 0px;
    font-size: 16px;
  }

  .mva-button-container { 
    margin-top: 0;
  }

  .carousel-img-container {
    height: 300px;
  }
  
}

@media only screen and (max-width: 640px) {

  .banner-mva-button-container {
    display: flex;
    justify-content: flex-end;
  }

  .banner-mva-button-container {
    bottom: 52px;
  }

  .carousel-img-container {
    height: 250px;
  }
  
  iframe {
    height: 278px;
  }
}

@media only screen and (max-width: 375px) {
  .carousel-img-container {
    height: 152px;
  }
}
