.condition__page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-condition-container {
  width: 80%;
}

.admin__condition__title {
  display: flex;
  flex-direction: row;
  margin-top: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#condition-list-title {
  text-align: center;
  font-size: 40px;
  font-weight: 700;
}

.condition__layout {
  width: 100%;
  margin-top: 68px;
  display: flex;
  flex-direction: row;
}

.admin__condition {
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border: 1px solid #446647;
  justify-content: center;
  gap: 65px;
  padding: 100px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.condition__nameCard {
  padding: 5px 4px;
  background: #f1f1f1;
  border-bottom: 1px solid #446647;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  flex-direction: column;

  color: #676767;
}

.img__section {
  margin-right: 20px;
}

.condition__img {
  height: 240px;
}

.condition-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 0;
}

.condition__list {
  box-sizing: border-box;
  display: flex;
  margin: 0 10px 10px 5px;
}

.condition-picture-container {
  box-sizing: border-box;
  width: 20vw;
  max-width: 100px;
  max-height: 100px;
  margin: 0 5px 0 0;
  display: flex;
  justify-content: center;
}

.condition__sm__img {
  height: 100px;
  max-width: 100%;
  object-fit: contain;
}

.condition-no-picture {
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  border: solid 0.5px black;
  text-align: center;
  display:flex;
  justify-content: center;
  align-items: center;  
}

.condition__group {
  width: 80%;
  min-width: 80px;
}

.admin-condition-component-btn {
  padding: 5px;
  border-radius: 5px;
  margin: 5px 0 10px 0;
  border: none;
  color: white;
}
.admin-condition-edit-btn {
  background-color: #82ac5c;
  margin-right: 15px;
}
.admin-condition-del-btn {
  background-color: #eb5066;
}

.condition__btn {
  align-self: flex-end;
  padding: 0 10px;
  width: 250px;
  height: 35px;
  background: linear-gradient(180deg, #82ac5c 0%, #78a67a 100%);
  border-radius: 10px;
  color: white;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  box-shadow: 0px 5px 5px lightgray;
}

.condition__btn:hover {
  transform: scale(1.02);
}

.condition__btn:active {
  transform: scale(1);
}

@media only screen and (max-width: 1366px) {
  
  .condition-grid-container {
    grid-template-columns: 1fr 1fr 1fr;
  }

}

@media only screen and (max-width: 1024px) {
  
  .admin__condition__title {
    flex-direction: column;
  }  

  .condition-grid-container {
    grid-template-columns: 1fr 1fr;
  }

}

@media only screen and (max-width: 640px) {
  
  .condition-grid-container {
    grid-template-columns: 1fr;
  }

}