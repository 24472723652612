.admin-faq-comp-container {
  margin-bottom: 2vh;
}

.admin-faq-quest-comp {
  display: flex;
  align-items: center;
}

#admin-faq-quest-title {
  max-width: 75%;
  font-family: "Inter";
  padding-left: 2vw;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  white-space: pre-line;
  /* identical to box height */

  color: #000000;

  /* Inside auto layout */

  flex: none;
  flex-grow: 0;
}

#hard-line {
  margin-top: 1vh;
  height: 0px;
  border: 1px solid rgba(0, 0, 0, 0.45);
  /* Inside auto layout */
  flex: none;
  order: 2;
  flex-grow: 0;
}

#greenbox {
  position: relative;
  width: 50px;
  height: 50px;
  min-width: 20px;
  max-width: 67px;
  min-height: 20px;
  max-height: 64px;
  background: #446647;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

#greenbox:hover {
  transform: scale(1.1);
}

#greenbox:active {
  transform: scale(1);
}

#plus-sign-hort {
  position: absolute;
  width: 60%;
  height: 0px;
  left: 0px;
  top: 0px;
  border: 2px solid #ffffff;
  transform: rotate(-90deg);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#plus-sign-vert {
  position: absolute;
  width: 0%;
  height: 60%;
  left: 0px;
  top: 0;
  border: 2px solid #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.1s ease-out;
  opacity: 1;
}

#plus-sign-vert-open {
  opacity: 0;
}

.admin-faq-title-edit-delete-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

#admin-faq-ans {
  font-size: 24px;
  white-space: pre-line;
}

.admin-faq-component-btn {
  padding: 5px;
  border-radius: 5px;
  margin: 20px 0px;
  border: none;
  color: white;
}

.admin-faq-edit-btn {
  background-color: #82ac5c;
  margin-right: 15px;
}

.admin-faq-del-btn {
  background-color: #eb5066;
}
