@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.condition-card-container {
    box-sizing: border-box;
    border: 1px solid #000000;
    width: 100%;
    height: inherit;
    background-color: #cccccc;
    position: relative;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
}

#condition-title-container, #condition-learn-more-container, #condition-desc-container {
    box-sizing: border-box;
    
    color: #fafffd;
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: ease-out 0.4s;
}

#condition-title-container, #condition-learn-more-container {
    background: rgba(0, 0, 0, 0.5);
    height: 80px;
    justify-content: center;
}

#condition-title-container {
  top: 0;
}

#condition-learn-more-container {
    bottom: 0;
    text-decoration: underline;
}

#condition-desc-container {
  background: rgba(0, 0, 0, 0.6);
  height: inherit;
  margin: 0px;
  justify-content: flex-start;
  padding: 30px 24px 0;
  overflow: auto;
  opacity: 0;
  display: flex;
}

.condition-card-container:hover #condition-desc-container {
  opacity: 1;
}

.condition-card-container:hover #condition-title-container, 
.condition-card-container:hover #condition-learn-more-container {
  opacity: 0;
}

#condition-title, #condition-learn-more {
    box-sizing: border-box;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;

    display: flex;
    justify-content: center;
    align-items: center;
}

#condition-desc {
    box-sizing: border-box;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  margin: 20px 0 24px;
  text-align: center;
}

#condition-desc__left {
    width: 100%;
    box-sizing: border-box;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    display: flex;
    margin: 32px 0 30px;
    white-space: pre-line;
}

.service__btn {
  margin-top: 20px;
  padding-left: 52%;
}

@media only screen and (max-width: 800px) {
  #condition-desc-container {
    padding: 20px 15px 20px;
  }
  #service-desc {
    margin: 17px 0 0;
  }
  #condition-desc__left {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin: 17px 0 0;
  }
  #condition-title-container, #condition-learn-more-container {
    height: 77px;
    padding: 16px 0;
  }
  #condition-title, #condition-learn-more {
    font-weight: 700px;
    font-size: 16px;
    line-height: 20px;
  }
  #condition-learn-more {
    text-decoration: underline;
  }

}
