@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.admin-modal-team {
    box-sizing: border-box;
    width: 550px;
    position: fixed;
    top: 50%;
    left: 70%;
    transform: translate(-72%, -50%);
    background: #ffffff;
    border: 1px solid #446647;
    justify-content: center;
    gap: 65px;
    padding: 20px 20px 20px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.modal-team-content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-team-title {
    font-family: 'Inter';
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;

    margin-bottom: 20px;
}

.team__form{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
}

.modal-admin-team-img-container {
    max-width: 223px;
    max-height: 217px;
    display: flex;
    justify-content: center;
}

.modal-admin-team-img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.modal-admin-team-upload-text-container {
    box-sizing: border-box;
  
    height: 217px;
    width: 223px;
    border: solid 0.5px black;
    text-align: center;
    display:flex;
    justify-content: center;
    align-items: center;  
    border: 1px solid black;
}

.modal-admin-team-input-container {
    width: 100%;
    max-width: 500px;
}

.modal-admin-team-input-field {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    font-size: 16px;
    font-weight: 700;
    font-family: "inter";
    color: #676767;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.modal-admin-team-button {
    align-self: flex-end;
    background: linear-gradient(180deg, #82ac5c 0%, #78a67a 100%);
    border-radius: 5px;
    color: white;
    border: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    padding: 6px 24px;
    box-shadow: 0px 5px 5px lightgray;
    margin-right: 10px;
}

.modal-admin-team-button:hover {
    box-shadow: 0px 5px 5px gray;
}

.modal-admin-team-button:active {
    transform: scale(0.98);
}

.modal-admin-team-manage-file-container {
    margin-top: 5px;
}

.modal-admin-team-choose-file-container {
    width: 100%;
    height: 25px;
    padding: 0;
}

.modal-admin-team-delete-button-container {
    width: 100%;
    margin: 0;
    padding: 0;
}

.modal-admin-team-buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.modal-admin-team-close-form {
    box-sizing: border-box;
    width: 35px;
    height: 35px;
    position: absolute;
    right: 1.25%;
    top: 1.3%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    text-align: center;
    margin: 0 auto;
    font-weight: 600;
    color: #446647;
    font-family: "Arial", Courier, monospace;
    border: solid 3px #446647;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2 ease-in;
    padding-left: 1px;
}