.services-page-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.services__page {
    width: 80%;
}

.admin-services-title-add-member-container {
    display: flex;
    flex-direction: row;
    margin-top: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.admin-services-title-wrapper {
    display: flex;
}

.admin-services-members-title {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
}

.admin-services-add-button {
    align-self: flex-end;
    padding: 0 10px;
    width: 250px;
    height: 35px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: linear-gradient(180deg, #82AC5C 0%, #78A67A 100%);
    border-radius: 10px;
    border: 0;
    box-shadow: 0px 5px 5px lightgray;
    color: #ffffff;
}

.admin-services-add-button:hover {
    transform: scale(1.02);
}
  
.admin-services-add-button:active {
    transform: scale(1);
}
  
.services__layout {
    width: 100%;
    margin-top: 68px;
    display: flex;
    flex-direction: row;
}

.services-grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: auto;
}

.services__list {
    display: flex;
    padding: 10px 0px 10px 0;
}

.services-picture-container {
    box-sizing: border-box;
    width: 20vw;
    max-width: 227px;
    max-height: 175px;
    margin: 0 20px 0 0;
    object-fit: contain;
}

.services__sm__img {
    max-width: 100%;
    max-height: 100%;
    margin-right: 20px;
    margin: auto;
    display: block;
}

.services-no-picture {
    box-sizing: border-box;
    width: 20vw;
    height: 217px;
    max-width: 227px;
    max-height: 175px;  
    border: solid 0.5px black;
    text-align: center;
    margin: 0 20px 0 0;
    display:flex;
    justify-content: center;
    align-items: center;  
}

.services__group {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

.services-fields-container {
    padding: 2px 0 0 0;

}

.services-field-container {
    width: 100%;
    padding-bottom: 2px;
  
    background: #F1F1F1;
    border-bottom: 1px solid #446647;
  
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height */
  
    display: flex;
    flex-direction: column;
  
    color: #676767;
  
}

.services-field-title {
    color: black;
    font-size: 12px;
}

.services-field-content {
    min-height: 16px;
    white-space: pre-line;
}

.admin-services-component-btn {
    padding: 5px;
    border-radius: 5px;
    margin: 5px 0 20px 0;
    border: none;
    color: white;
}

.admin-services-edit-btn {
    background-color: #82ac5c;
    margin-right: 15px;
}

.admin-services-del-btn {
    background-color: #eb5066;
}

@media only screen and (max-width: 1024px) {
  
    .admin-services-title-add-member-container {
      flex-direction: column;
    }  
  
    .services__list {
      flex-direction: column;
      align-items: center;
    }  
  
    .services-picture-container {
      width: 100%;
    }
  
    .services-no-picture {
      width: 100%;
    }
    
  }
