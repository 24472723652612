.services-container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.services-title {
  height: 70px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  color: #000000;
  margin: 120px 0px 0px 0px;

  display: flex;
  align-items: center;
}

.services-cards-container {
  box-sizing: border-box;
  width: 85%;
  max-width: 1024px;
  margin: 80px 0 170px 0;
  justify-content: space-around;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12rem 1rem;
  padding-bottom: 50px;
}

.services-card-container {
  justify-self: center;
  box-sizing: border-box;
  width: 500px;
  height: 600px;
  border: 0 solid black;
}

@media only screen and (max-width: 1200px) {
  .services-cards-container {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .services-title {
    font-size: 18px;
    height: 24px;
    line-height: 22px;
    margin: 60px 0px 0px 0px;
  }
  .services-cards-container {
    margin: 30px 0 170px 0;
    grid-gap: 9rem 1rem;
  }
  .services-card-container {
    width: 315px;
    min-width: 315px;
    max-width: 315px;
    height: 378px;
  }
}
