.team-page-container {
  display: flex;
  justify-content: center;
}

.team__page {
    width: 80%;
}
  
.team__layout { 
  width: 100%;
  margin-top: 68px;
  display: flex;
  flex-direction: row;
}
  
.img__section {
  margin-right: 20px;
}

.team-picture-container {
  box-sizing: border-box;
  width: 20vw;
  max-width: 223px;
  max-height: 217px;
  margin: 0 20px 0 0;
  object-fit: contain;
}

.team__sm__img {
  max-width: 100%;
  max-height: 100%;
  margin-right: 20px;
  margin: auto;
  display: block;
}

.team-grid-container {
  width: 100%;
  display: grid;
  grid-template-columns: auto;
}

.team__list {
  display: flex;
  padding: 0 0 10px;
}

.admin-team-title-add-member-container {
  display: flex;
  flex-direction: row;
  margin-top: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-team-title-wrapper {
  display: flex;
}

.admin-team-members-title {
  font-size: 40px;
  font-weight: 700;
  text-align: center;
}

.admin-team-add-button {
  padding: 0 10px;
  height: 35px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: linear-gradient(180deg, #82AC5C 0%, #78A67A 100%);
  border-radius: 10px;
  border: 0;
  box-shadow: 0px 5px 5px lightgray;
  color: #ffffff;
}

.admin-team-add-button:hover {
  transform: scale(1.02);
}

.admin-team-add-button:active {
  transform: scale(1);
}

.team__group {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.team-fields-container {
  width: 100%;
  margin-bottom: 5px;
  padding: 2px 0 0 0;
}

.team-field-container {
  padding-bottom: 2px;

  background: #F1F1F1;
  border-bottom: 1px solid #446647;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  flex-direction: column;

  color: #676767;

}

.team-field-title {
  color: black;
  font-size: 12px;
}

.team-no-picture {
  box-sizing: border-box;
  height: 217px;
  max-width: 223px;
  max-height: 217px;  
  border: solid 0.5px black;
  text-align: center;
  display:flex;
  justify-content: center;
  align-items: center;  
}

.admin-team-component-btn {
  padding: 5px;
  border-radius: 5px;
  margin: 5px 0 20px 0;
  border: none;
  color: white;
}

.admin-team-edit-btn {
  background-color: #82ac5c;
  margin-right: 15px;
}

.admin-team-del-btn {
  background-color: #eb5066;
}

@media only screen and (max-width: 1024px) {
  
  .admin-team-title-add-member-container {
    flex-direction: column;
  }  

  .team__list {
    padding: 10px 0px 10px 0;
    flex-direction: column;
    align-items: center;
  }  

  .team-picture-container {
    width: 100%;
  }

  .team-no-picture {
    width: 100%;
  }
  
}

