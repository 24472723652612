.modal-admin-service-container {
    box-sizing: border-box;
    position: fixed;
    top: 50%;
    left: 70%;
    transform: translate(-70%, -50%);
    width: 500px;
    background: #ffffff;
    border: 1px solid #446647;
    justify-content: center;
    gap: 65px;
    padding: 15px 20px 30px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  
    display: flex;
    flex-direction: column;
    gap: 25px;

    
}

.modal-service-content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-admin-service-close-form {
    box-sizing: border-box;
    width: 35px;
    height: 35px;
    position: absolute;
    right: 1.25%;
    top: 1.3%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    text-align: center;
    margin: 0 auto;
    font-weight: 600;
    color: #446647;
    font-family: "Arial", Courier, monospace;
    border: solid 3px #446647;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2 ease-in;
    padding-left: 1px;
}

.modal-service-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;

    margin-bottom: 20px;
}

.service__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-admin-service-img-container {
    width: 227px;
    height: 175px;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
}

.modal-admin-service-img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.modal-admin-service-upload-text-container {
    box-sizing: border-box;
    width: 227px;
    height: 175px;
    border: solid 0.5px black;
    text-align: center;
    display:flex;
    justify-content: center;
    align-items: center;  
    border: 1px solid black;
}

.modal-admin-service-manage-file-container {
    margin: 5px 0;
}

.modal-admin-service-choose-file-container {
    width: 100%;
    height: 25px;
    padding: 0;
}

.modal-admin-service-delete-button-container {
    width: 100%;
    margin: 0;
    padding: 0;
}

.modal-admin-service-input-container {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
}

.modal-admin-service-input-field {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    font-size: 16px;
    font-weight: 700;
    font-family: "inter";
    color: #676767;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.modal-admin-service-textarea-field {
    
    box-sizing: border-box;
    width: 100%;
    height: 150px;
    
    align-content: left;
    font-size: 16px;
    font-weight: 700;
    font-family: "inter";
    color: #676767;
    background: #F1F1F1;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #446647;

    text-align: left;
}

.modal-admin-service-textarea-field::placeholder {
    font-size: 16px;
}

.modal-admin-service-buttons-container {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.modal-admin-service-button {
    align-self: flex-end;
    background: linear-gradient(180deg, #82ac5c 0%, #78a67a 100%);
    border-radius: 5px;
    color: white;
    border: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    padding: 6px 24px;
    box-shadow: 0px 5px 5px lightgray;
    margin-right: 10px;
}

.modal-admin-service-button:hover {
    box-shadow: 0px 5px 5px gray;
}

.modal-admin-service-button:active {
    transform: scale(0.98);
}

