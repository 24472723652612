#admin-faq-frame {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#admin-faq-container {
    width: 80%;
}

.admin-faq-title-add-faq-container {
    margin-top: 44px;
    margin-bottom: 5vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.admin-faq-title-wrapper {
    margin-right: 20px;
    display: flex;
}

#faq-list-title{
    text-align: center;
    font-size: 40px;
    font-weight: 700;
}

.admin-faq-add-button {
    align-self: flex-end;
    padding: 0 10px;
    width: 250px;
    height: 35px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: linear-gradient(180deg, #82AC5C 0%, #78A67A 100%);
    border-radius: 10px;
    border: 0;
    box-shadow: 0px 5px 5px lightgray;
    color: #ffffff;
}

.admin-faq-add-button:hover {
    transform: scale(1.02);
}
  
.admin-faq-add-button:active {
    transform: scale(1);
}

.faq__layout {
    display: flex;
    flex-direction: row;
}

.faq__list {
    display: flex;
    padding: 10px;
}

.faq__group {
    display: flex;
    flex-direction: column;
}

.faq-info-container {
    width: 100%;
}

@media only screen and (max-width: 1024px) {
    .admin-faq-title-add-faq-container {
      flex-direction: column;
    }  
}