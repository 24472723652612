#user-container{
    display:flex;
    border: solid 1px #446647;
    border-radius: 4px;
    width:100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding: 5px 0;
}

#user-user-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:2vw;
}

#user-avatar{
    border-radius: 50%;
    box-sizing: border-box;
    object-fit: cover;
    width: 50px;
    height: 50px;
    font-size: 0.5em;
}

#button-wrapper{
    display: flex;
    gap:2vw;
    margin-right: 19px;
}

#editButton{
    background:#82AC5C;
    width: 45px;
    height: 45px;
    color:white;
    border-radius: 5px;
}

#deleteButton{
    background:red;
    width: 45px;
    height: 45px;
    color:white;
    border-radius: 5px;
}

.user-avatar-container {
    width: 50px;
    height: 50px;
    margin-left: 1rem;
    border: solid 0px black;
    border-radius: 50%;
    text-align: center;
    font-size: 12px;
    display:flex;
    justify-content: center;
    align-items: center;
}

.username-email-container {
    display: flex;
    flex-direction: column;
    min-width: 100px;
    justify-content: center;
    padding: 0 5px;
}

.username-container {
    display: flex;
    align-items: flex-start;
    font-size: 24px;
    font-weight: 700;
}

.email-container {
    min-width: 80px;
}

.isAdmin-LoggedIn-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-width: 100px;
}

.isAdmin-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80px;
}

.LoggedIn-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80px;
}

.empty-button {
    width: 45px;
    height: 45px;  
}

.disabled-button {
    opacity: 0.5;
}

.no-avatar {
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    border: solid 0.5px black;
    border-radius: 50%;
    text-align: center;
    display:flex;
    justify-content: center;
    align-items: center;  
}

@media only screen and (max-width: 1024px) {
    #user-container{
        flex-direction: column;
    }
}