.scroll {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: 1rem;
  bottom: 2.5rem;
  z-index: 9;
  cursor: pointer;
  text-decoration: none;
}

.scroll__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: black;
}
