/* ---- testimonial section - home page ----- */

.testimonial-stars {
  font-size: 50px;
  color: gold;
  margin-right: 20px;
}

.testimonial-card {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  background: linear-gradient(180deg, #729e74 0%, #476a4a 100%);
  border-radius: 30px;
  width: 100%;
  height: 100%;
  padding: 30px 0;
}

.testimonial-btns {
  display: flex;
  width: inherit;
  justify-content: space-evenly;
  align-items: center;
  height: 80px;
}

.testimonial-btns > a > button {
  border-radius: 15px;
  width: 328px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.testimonial-btns > a > button > span {
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  color: #476a4a;
}

.test-desc {
  text-align: center;
  width: 84%;
  font-size: 24px;
  color: #ffffff;
  font-weight: 400;
  line-height: 29.05px;
}

.test-name {
  text-align: center;
  color: #ffffff;
  font-size: 32px;
  font-weight: 700;
  line-height: 38.73px;
}

@media only screen and (max-width: 800px) {
  .testimonial-card {
    margin-top: 10px;
    border-radius: 15px;
  }

  .test-desc {
    font-size: 14px;
    line-height: 17px;
  }

  .test-name {
    font-size: 12px;
  }

  .testimonial-btns {
    height: 40px;
  }

  .testimonial-btns > a > button {
    border-radius: 5px;
    width: 125px;
    height: 36px;
  }

  .testimonial-btns > a > button > span {
    margin: 0;
    font-size: 14px;
  }
}

@media only screen and (max-width: 640px) {
  .testimonial-stars {
    font-size: 30px;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 350px) {
  .testimonial-stars {
    font-size: 15px;
    margin-right: 6px;
  }

  .testimonial-btns {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80px;
  } 

  .testimonial-btns > a > button {
    border-radius: 5px;
    width: 100px;
    height: 36px;
  }
  
  .testimonial-btns > a > button > span {
    font-size: 12px;
    height: 42px;
  }
}