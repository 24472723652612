.button {
  box-sizing: border-box;
  display: flex;
  background: linear-gradient(180deg, #82AC5C 0%, #78A67A 100%);
  color: #ffffff;
  justify-content: center;
  align-items: center;
  width: 239px;
  height: 66px;
  border: 0;
  border-radius: 6.75px;
}

.button-content {
  text-decoration: none;
  color: #ffffff;
  font-size: 18.9px;
  font-weight: 700;
}

.button:hover {
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .button {
    width: 152px;
    height: 37px;
  }
  .button-content {
    font-size: 12px;
  }
}