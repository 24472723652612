:root {
    --card-max-container-width: 502px;
    --card-max-container-height: 634px;
    --card-mobile-container-width: 315px;
    --card-mobile-container-height: 398px;
    --card-max-grid-gap: 5.625rem 1.25rem;
    --card-max-grid-gap-x: 1.25rem;
    --card-max-grid-gap-y: 5.625rem;
    --card-mobile-grid-gap-y: 5.8125rem;
}

.team-container {
    height: 100%;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.team-content-container {
    width: 80%;
    max-width: 1024px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.our-team-title {
    height: 70px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    line-height: 58px;
    text-align: center;
    color: #000000;
    width: 100%;
    max-width: 1023px;
    margin: 120px auto 70px;
}

.our-team-cards {
    box-sizing: border-box;
    width: 100%;
    justify-content: space-around;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--card-max-grid-gap-y) var(--card-max-grid-gap-x);
    margin: 0 auto 120px;
}

.team-card-container {
    box-sizing: border-box;
    display: flex;
    justify-self: center;
    width: var(--card-max-container-width);
    height: var(--card-max-container-height);
}

@media only screen and (max-width: 1200px) {
    .team-card-container {
        width: calc(var(--card-max-container-width)*.8);
        height: calc(var(--card-max-container-height)*.8);
    }
}

@media only screen and (max-width: 1024px) {
    .our-team-cards {
        grid-template-columns: 1fr;        
    }

}

@media only screen and (max-width: 800px) {
    .our-team-cards {
        grid-gap: var(--card-mobile-grid-gap-y);
        margin: 0 auto 153px;
    }    
    .team-card-container {
        width: var(--card-mobile-container-width);
        height: var(--card-mobile-container-height);
    }
    .our-team-title {
        height: 24px;
        font-size: 18px;
        line-height: 22px;
        margin: 60px auto 30px;
    }
}
