.adminGrid {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 69px calc(100% - 69px);
  grid-template-areas:
    "header header header header header header"
    "sidebar main main main main main"
    "sidebar main main main main main";
}

#header {
  grid-area: header;
}

#sidebar {
  grid-area: sidebar;
}

#main {
  grid-area: main;
}
