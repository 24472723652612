.c-u-info-container-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;

  display: none;
}

.c-u-info-title-mobile {
  text-align: center;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;

  color: #000000;

  margin: 61px 0 30px 0;
}

.c-u-info-table-mobile {
  border-collapse:collapse;
  border-style: hidden;
}

.c-u-info-tr-mobile {
  text-align: left;
  vertical-align: baseline;
  border: 18px solid transparent
}

.c-u-info-th-mobile {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;

  padding: 0 5px 0 0;
}

.c-u-info-td-mobile {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

#c-u-container {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1vh;
  align-items: center;
}

#c-u-pic {
  width: 100%;
  height: 100%;
}

#c-u-title-container {
  width: 74%;
  max-width: 1024px;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
}

#c-u-title {
  height: 138px;
  text-align: left;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  color: #000000;
}

#c-u-description {
  width: auto;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  color: #000000;
}

/* FORM */

#c-u-form-container {
  box-sizing: border-box;
  background: linear-gradient(180deg, #729e74 0%, #446647 100%);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 920px;
  padding: 32px 0;
  margin: 100px 0;
}
#text-input-container {
  display: flex;
  width: 83%;
  flex-direction: column;
  justify-content: flex-start;
  margin: 10px 0;
}

#check-container {
  margin: 1px 0;
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

#textarea-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  margin: 10px 0;
}

#c-u-label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
}

#c-u-input {
  box-sizing: border-box;
  width: 850px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #ffffff;
}
#c-u-checkbox {
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #ffffff;
}

#c-u-checkbox-label {
  height: 30px;
  line-height: 30px;
  left: 348px;
  top: 1567px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;

  color: #ffffff;

  display: table-cell;
  vertical-align: middle;
}

#c-u-textarea {
  box-sizing: border-box;
  width: 100%;
  max-width: 850px;
  height: 122px;
  background: #ffffff;
  border: 1px solid #ffffff;
  margin-top: 20px;

  font-family: "inter";
  font-size: 24px;
}

#c-u-button {
  width: 247.15px;
  height: 56px;
  background: #ffffff;
  border-radius: 6.5px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  
  color: #5A736D;
}

/* MAP */

#map-container {
  width: 74%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}
#map-title {
  box-sizing: border-box;
  width: 100%;
  max-width: 1024px;
  height: 58px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  color: #000000;
  margin-bottom: 30px;
}
.c-u-locations-map-container {
  box-sizing: border-box;
  width: 100%; 
}
.c-u-locations-map {
  box-sizing: border-box;
  min-height: 278px;
}

@media only screen and (max-width: 800px) {
  .c-u-info-container-mobile {
    display: flex;
  }

  #c-u-title-container {
    width: 84%;
  } 
  #c-u-title {
    font-size: 20px;
    line-height: 24px;
    height: 48px;
    white-space: normal;
  }
  #c-u-description {
    font-size: 14px;
    line-height: 17px;
  }

  /* FORM */  
  #c-u-form-container {
    margin: 30px 0;
  }
  #c-u-label {
    font-size: 14px;
    line-height: 17px;
  } 
  #text-input-container {
    width: 91%;
  }
  #c-u-checkbox-label {
    font-size: 14px;
    list-style: 17px;
  }
  #c-u-textarea {
    font-size: 14px;
  }
  #c-u-button {
    width: 285px;
    height: 48px;

    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
  #map-container {
    width: 84%;
  }
  #map-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    height: 24px;
    margin-bottom: 15px;
  }
  .c-u-locations-map {
    min-height: 278px;
  }
}

