#admin-nav-container {
  background: linear-gradient(180deg, #82ac5c 0%, #78a67a 100%);
  height: 100%;
  width: 20vw;
  max-width: 230px;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  gap: 0.2vh;
}

.admin-nav-button {
  height: 50px;
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  text-align: left;

  color: #ffffff;
  padding: 0;
}

.admin-nav-button:hover {
  background: #446647;
  text-decoration: none;
  opacity: 0.7;
}

.admin-nav-button-selected {
  height: 50px;
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */
    background: #446647;
  border: none;
  display: flex;
  align-items: center;
  text-align: left;

  color: #ffffff;
  text-decoration: none;
  padding: 0;
}

.admin-nav-link {
  width: 100%;
  height: 50px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  padding: 0 0 0 5px;
}

.admin-nav-link:hover {
  text-decoration: none;
}

@media only screen and (max-width: 800px) {
  .admin-nav-button {
    font-weight: 600;
    font-size: 18px;
  }

  .admin-nav-button-selected {
    font-weight: 600;
    font-size: 18px;
  }
}