@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.admin__user {
    
    box-sizing: border-box;
    position: fixed;
    top: 50%;
    left: 70%;
    transform: translate(-70%, -50%);
    background: #ffffff;
    border: 1px solid #446647;
    justify-content: center;
    gap: 65px;
    padding: 20px 0px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 500px;
}

.modal-container {
    width: 90%;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.modal-admin-user-title-container {
    padding: 0;
    margin-bottom: 1rem;
    text-align: center;
}

.modal-admin-user-title {
    font-size: 32px;
    font-weight: 700;
    text-align: center;
}

.admin_user-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.modal-admin-user-img-container {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 1px solid black;
    margin-bottom: 5px;
}

.modal-admin-user-img {
    box-sizing: border-box;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.modal-admin-user-input-container {
    width: 100%;
}

.modal-admin-user-input-field {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    font-size: 16px;
    font-weight: 700;
    font-family: "inter";
    color: #676767;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.modal-admin-user-upload-text-container {
    text-align: center;
    padding: 70px 0;
}

.modal-admin-user-checkbox-field {
    box-sizing: border-box;
    width: 7%;
    font-size: 16px;
    height: 16px;
    margin: 10px 0;
}

.modal-admin-user-label {
    font-size: 16px;
}

.modal-admin-user-buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.modal-admin-user-button {
    align-self: flex-end;
    background: linear-gradient(180deg, #82ac5c 0%, #78a67a 100%);
    border-radius: 5px;
    color: white;
    border: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    padding: 6px 24px;
    box-shadow: 0px 5px 5px lightgray;
    margin-right: 10px;
}

.modal-admin-user-button:hover {
    box-shadow: 0px 5px 5px gray;
}

.modal-admin-user-button:active {
    transform: scale(0.98);
}

.modal-admin-user-manage-file-container {
    margin-top: 5px;
}

.modal-admin-user-choose-file-container {
    width: 100%;
}

.modal-admin-user-delete-button-container {
    width: 100%;
}

.modal-admin-user-close-form {
    box-sizing: border-box;
    width: 35px;
    height: 35px;
    position: absolute;
    right: 1.25%;
    top: 1.3%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    text-align: center;
    margin: 0 auto;
    font-weight: 600;
    color: #446647;
    font-family: "Arial", Courier, monospace;
    border: solid 3px #446647;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2 ease-in;
    padding-left: 1px;
  }
