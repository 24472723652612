.nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 126px;
}

.nav-logo-container {
  box-sizing: border-box;
  width: 347px;
  height: inherit;
  display: flex;
  flex-direction: row;
}

.nav-logo {
  width: 100%;
  object-fit: cover;
}

.nav-btn-container {
  position: absolute;
  right: 107px;
}



.nav-links-container {
  float: right;
  display: flex;
  padding: 5px 0; 
}

.menu-icon-container {
  width: 40px;
  height: 40px;
  display: none;
  margin: 0 20px 0 0;

}

.menu-icon-bar1, .menu-icon-bar2, .menu-icon-bar3 {
  width: 30px;
  height: 0;
  border: 4px solid #446647;
  border-radius: 5px;
  margin: 5px 0;
  transition: 0.4s;
}

.change .menu-icon-bar1 {
  transform: translate(0, 14px) rotate(-48deg);
}

.change .menu-icon-bar2 {
  opacity: 0
}

.change .menu-icon-bar3 {
  transform: translate(0, -12px) rotate(48deg)

}

.hamburger-link {
  width: 50px;
  height: 50px;
}

.nav-subheader {
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #446647;
  height: 100%;
  max-height: 55px;
  margin: 0px 0px;
}

.nav-subheader-items-container {
  list-style: none;
  padding-left: 0;
  display: flex;
  height: 55px;
}

.item-title {
  box-sizing: border-box;
  width: 100%;
  height: inherit;
  padding: 0 10px 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.item-title-name {
  width: 200px;
  text-align: center;
}

.dropdown {
  width: inherit;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0;
  white-space: nowrap;
}

.dropdown-menu {
  display: none;
  flex-direction: column;
  position: absolute;
  width: 328px;
  top: 181px;
  padding: 0px;
  margin: 0;
  background-color: #446647;
  border-radius: 0px 10px 10px 10px;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

li {
  display: flex;
  color: #ffffff;
  justify-content: left;
  align-items: center;
  
  font-family: "inter";
  font-weight: 600;
  font-size: 23px;
  height: inherit;
  width: 154px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
}

li:hover {
  background-color: #82ac5c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.dropdown-item {
  display: flex;
  justify-content: flex-start;
  width: inherit;
  height: 50px;
  text-align: left;
  list-style: none;
}

.dropdown-item-title {
  padding: 0 10px;
  width: 100%;
  height: inherit;
  display: inline-flex;
  justify-content: left;
  text-align: left;
}

@media only screen and (max-width: 1024px) {
  li {
    width: 115px;
    font-size: 18px;
  }
  ul.dropdown-menu {
    width: 200px;
    padding: 0px;
  }
  .dropdown-item {
    width: inherit;
    height: 36px;
    font-size: 16px;
  }
}

/* styles for menu icon and horizontal menu */
@media only screen and (max-width: 800px) {
  .nav-subheader-container {
    display: flex;
    align-items: flex-start;
    position: relative;
  }

  .nav-subheader {
    align-items: flex-start;
  }

  .nav-subheader-items-container {
    display: none;
  }

  .menu-icon-container {
      display: block;
  }
}

/* styles for responsive menu */
@media only screen and (max-width: 800px) {

  .nav-subheader-items-container.responsive {
      box-sizing: border-box;
      background: #446647;
      border-radius: 0px 10px 10px 10px;
      display: flex;
      flex-direction: column;
      width: 140px;
      height: 201px;
      float: left;
      display: block;
      position: absolute;
      left: 0;
      z-index: 99;
  }

  .nav-subheader-items-container.responsive li {
    width: inherit;
    height: 33px;
  }

  .nav-subheader-items-container.responsive div.item-title {
      height: inherit;
      text-align: left;
      display: flex;
      justify-content: left;
  }

  .nav-subheader-items-container.responsive .item-title-name {
    text-align: left;
  }
  
  .nav-subheader-items-container.responsive div.dropdown {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .nav-subheader-items-container.responsive ul.dropdown-menu {
    position: relative;
    top: 0px;
  }

}

@media only screen and (max-width: 800px) {
  .nav-header {
    height: 80px;
  }
  .nav-logo-container {
    box-sizing: border-box;
    width: 188px;
    height: 80px;
  }
  .nav-logo {
    width: 100%;
  }
  li {
    width: 115px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 640px) {
  .nav-btn-container {
    display: none;
  }

  .nav-subheader-items-container {
    flex-direction: column;
  }
}

