.prices-group {
  display: flex;
  flex-direction: column;
  height: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-prices-container {
  width: 80%;
}

.admin-prices-title-wrapper {
  margin-top: 44px;
  margin-bottom: 5vh;
  margin-right: 20px;
}

#admin-prices-list-title {
  text-align: center;
  font-size: 40px;
  font-weight: 700;
}

.services-list {
  display: flex;
  flex-direction: column;
}

.admin-prices-title-add-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 20px 0;
}

.admin-prices-category {
  font-weight: 700;
  font-size: 25px;
  padding: 0 0 2px;
  font-family: "Inter";
  font-style: normal;
}

.admin-prices-item-card {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.component-btn {
    padding: 5px;
    border-radius: 5px;
    margin: 20px 0px;
    border: none;
    color: white;
}
.edit-btn {
    background-color: #82ac5c;
    margin-right: 15px;
}
.del-btn {
    background-color: #eb5066;
}

.admin-prices-component-btn {
  padding: 5px;
  border-radius: 5px;
  margin: 5px 0 20px 0;
  border: none;
  color: white;
}

.admin-prices-edit-btn {
  background-color: #82ac5c;
  margin-right: 15px;
}

.admin-prices-del-btn {
  background-color: #eb5066;
}

.admin-prices-add-newRates {
  align-self: flex-end;
  padding: 0 10px;
  width: 250px;
  height: 35px;

  background: linear-gradient(180deg, #82ac5c 0%, #78a67a 100%);
  border-radius: 10px;
  color: white;
  border: none;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;

  box-shadow: 0px 5px 5px lightgray;
  
}

.admin-prices-add-newRates:hover {
  transform: scale(1.02);
}

.admin-prices-add-newRates:active {
  transform: scale(1);
}

.add-newRates:hover {
  background: #d9d9d9;
  color: #82ac5c;
}

@media only screen and (max-width: 800px) {

  .admin-prices-title-wrapper {
    display: flex;
    justify-content: center;
  }

  .admin-prices-title-add-container {
    flex-direction: column;
  }  
  
}